import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'

import Table from '../../../Components/Common/Table'
import Filters from './Filters'
import { tableColumns } from './columnsConfig'
import { GetTransfers } from '../../../infra/requests/TransfersRequests'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

const TransfersPage = () => {
  const { clients, users } = useSelector((state) => state.Info.data)
  const [filters, setFilters] = useState({
    search: '',
    b2bClientId: [],
    purchaseOrder: '',
    b2bOrderNumber: '',
  })

  const [columnFilters, setColumnFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [tableData, setTableData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)

  const [loadInitial, setLoadInitial] = useState(true)
  const [filterOptions, setFilterOptions] = useState({
    b2bClients: [],
    originWarehouses: [],
    destinyWarehouses: [],
    users: [],
    statusLabel: [],
  })

  const isFetchingRef = useRef(false)
  const lastRequestParamsRef = useRef(null)

  const handleColumnFiltersChange = (newFilters) => {
    setColumnFilters(newFilters)
  }

  const getApiParameters = () => {
    const params = {
      freeSearch: filters.search || '',
      orderPoSearch: filters.purchaseOrder || '',
      b2bOrderNumberSearch: filters.b2bOrderNumber || '',
      page: currentPage,
      itemsPerPage: rowsPerPage,
      languageID: 2,
      b2bClientId: '',
    }

    if (filters.b2bClientId) {
      if (Array.isArray(filters.b2bClientId)) {
        if (filters.b2bClientId.length > 0) {
          params.b2bClientId = filters.b2bClientId.join(',')
        }
      } else {
        params.b2bClientId = String(filters.b2bClientId)
      }
    }

    if (columnFilters) {
      if (columnFilters.client && Array.isArray(columnFilters.client) && columnFilters.client.length > 0) {
        const clientIds = columnFilters.client
          .map((clientName) => {
            const client = clients.find((c) => c.name === clientName)
            return client ? client.b2bclientId : null
          })
          .filter((id) => id !== null)

        if (clientIds.length > 0) {
          params.b2bClientId = clientIds.join(',')
        }
      }

      if (columnFilters.status && Array.isArray(columnFilters.status)) {
        const statusValues = columnFilters.status
          .map((statusLabel) => {
            return statusLabel === 'Concluded' ? true : false
          })
          .filter((value) => value !== null)

        if (statusValues.length > 0) {
          params.status = statusValues.join(',')
        }
      }

      if (columnFilters.originWarehouse && Array.isArray(columnFilters.originWarehouse)) {
        const warehouseIds = columnFilters.originWarehouse
          .map((warehouseName) => {
            const warehouse = filterOptions.originWarehouses.find((w) => w.name === warehouseName)
            return warehouse ? warehouse.warehouseId : null
          })
          .filter((id) => id !== null)

        if (warehouseIds.length > 0) {
          params.originWarehouseId = warehouseIds.join(',')
        }
      }

      if (columnFilters.destinyWarehouse && Array.isArray(columnFilters.destinyWarehouse)) {
        const warehouseIds = columnFilters.destinyWarehouse
          .map((warehouseName) => {
            const warehouse = filterOptions.destinyWarehouses.find((w) => w.name === warehouseName)
            return warehouse ? warehouse.warehouseId : null
          })
          .filter((id) => id !== null)

        if (warehouseIds.length > 0) {
          params.destinyWarehouseId = warehouseIds.join(',')
        }
      }

      if (columnFilters.createdBy && Array.isArray(columnFilters.createdBy)) {
        const userIds = columnFilters.createdBy
          .map((userName) => {
            const user = users.find((u) => u.fullName === userName)
            return user ? user.userId : null
          })
          .filter((id) => id !== null)

        if (userIds.length > 0) {
          params.createdByUserId = userIds.join(',')
        }
      }

      if (columnFilters.date && Array.isArray(columnFilters.date) && columnFilters.date.length === 2) {
        const fromDate = columnFilters.date[0]
        const toDate = columnFilters.date[1]

        if (fromDate && toDate) {
          params.dateFrom = fromDate
          params.dateTo = toDate
        }
      }
    }

    return params
  }

  const shouldFetchData = useCallback(() => {
    if (
      columnFilters.date &&
      Array.isArray(columnFilters.date) &&
      columnFilters.date.length === 2 &&
      (!columnFilters.date[0] || !columnFilters.date[1]) &&
      !users.length &&
      !clients.length
    ) {
      return false
    }

    return true
  }, [columnFilters, users.length, clients.length])

  const fetchTransfers = useCallback(async () => {
    if (isFetchingRef.current) {
      return
    }

    if (!shouldFetchData()) {
      return
    }

    const params = getApiParameters()

    if (lastRequestParamsRef.current && JSON.stringify(lastRequestParamsRef.current) === JSON.stringify(params)) {
      return
    }

    lastRequestParamsRef.current = params

    isFetchingRef.current = true
    setLoading(true)

    try {
      const response = await GetTransfers(
        params.freeSearch,
        params.orderPoSearch,
        params.b2bOrderNumberSearch,
        params.page,
        params.itemsPerPage,
        params.languageID,
        params.status,
        params.originWarehouseId,
        params.destinyWarehouseId,
        params.createdByUserId,
        params.dateFrom,
        params.dateTo,
        params.b2bClientId,
      )

      setTableData(response.data)
      setTotalItems(response.data.transfers.totalItems)

      const formatClients = clients
        .filter((client) => {
          return response.data.b2bClients.some(
            (apiClient) =>
              apiClient.b2bClientId === client.b2bclientId ||
              String(apiClient.b2bClientId) === String(client.b2bclientId),
          )
        })
        .map((client) => {
          return {
            name: client.name,
            b2bClientId: client.b2bclientId,
          }
        })

      setFilterOptions({
        b2bClients: formatClients || [],
        originWarehouses: response.data.originWarehouses || [],
        destinyWarehouses: response.data.destinyWarehouses || [],
        users: response.data.users || [],
        statusLabel: response.data.statusLabel || [],
      })

      updateColumnFilters(response.data)
    } catch (error) {
      console.error('Error fetching transfers', error)
    } finally {
      setLoadInitial(false)
      setLoading(false)
      isFetchingRef.current = false
    }
  }, [clients, users, filters, columnFilters, currentPage, rowsPerPage, shouldFetchData])

  const processTransfersData = useMemo(() => {
    if (!tableData.transfers || !tableData.transfers.items) return []

    return tableData.transfers.items.map((transfer) => {
      const client = clients.find(
        (c) => c.b2bclientId === transfer.b2bClientId || String(c.b2bclientId) === String(transfer.b2bClientId),
      )

      const user = users.find((u) => u && u.userId === transfer.createdByUserId)

      const originWarehouse = tableData.originWarehouses.find((w) => w.warehouseId === transfer.originWarehouseId)

      const destinyWarehouse = tableData.destinyWarehouses.find((w) => w.warehouseId === transfer.destinyWarehouseId)

      return {
        ...transfer,
        b2bClientName: client?.name || '-',
        originWarehouseName: originWarehouse?.name || '-',
        destinyWarehouseName: destinyWarehouse?.name || '-',
        conclusionLabel: transfer.conclusionLabel ? 'Concluded' : 'Pending',
        createdByUser: user?.fullName || '-',
      }
    })
  }, [tableData, users, clients])

  const updateColumnFilters = (apiData) => {
    if (!apiData || !apiData.transfers || !apiData.transfers.items) return

    if (apiData.b2bClients && apiData.b2bClients.length > 0) {
      const clientIds = apiData.b2bClients.map((c) => c.b2bClientId)

      const clientsFromRedux = clients.filter((c) =>
        clientIds.some((id) => id === c.b2bclientId || String(id) === String(c.b2bclientId)),
      )

      const clientColumn = tableColumns.columns.find((col) => col.filterName === 'client')
      if (clientColumn) {
        if (clientsFromRedux.length === 0) {
          const uniqueClientNames = new Set()
          apiData.transfers.items.forEach((transfer) => {
            const client = clients.find(
              (c) => c.b2bclientId === transfer.b2bClientId || String(c.b2bclientId) === String(transfer.b2bClientId),
            )
            if (client && client.name) {
              uniqueClientNames.add(client.name)
            }
          })

          clientColumn.filters = Array.from(uniqueClientNames).map((name) => ({
            label: name,
            value: name,
          }))
        } else {
          clientColumn.filters = clientsFromRedux.map((client) => ({
            label: client.name,
            value: client.name,
          }))
        }
      }
    }

    if (apiData.users && apiData.users.length > 0) {
      const userIds = apiData.users.map((u) => u.userId)
      const usersFromRedux = users.filter((u) => u && userIds.includes(u.userId))

      const userColumn = tableColumns.columns.find((col) => col.filterName === 'createdBy')
      if (userColumn && usersFromRedux.length > 0) {
        userColumn.filters = usersFromRedux.map((user) => ({
          label: user.fullName,
          value: user.fullName,
        }))
      }
    }

    if (apiData.statusLabel) {
      const statusColumn = tableColumns.columns.find((col) => col.filterName === 'status')
      if (statusColumn) {
        const uniqueStatusLabels = new Set()

        apiData.statusLabel.forEach((status) => {
          uniqueStatusLabels.add(status.status ? 'Concluded' : 'Pending')
        })

        statusColumn.filters = Array.from(uniqueStatusLabels).map((label) => ({
          label,
          value: label,
        }))
      }
    }

    if (apiData.originWarehouses) {
      const originColumn = tableColumns.columns.find((col) => col.filterName === 'originWarehouse')
      if (originColumn) {
        originColumn.filters = apiData.originWarehouses.map((warehouse) => ({
          label: warehouse.name,
          value: warehouse.name,
        }))
      }
    }

    if (apiData.destinyWarehouses) {
      const destinyColumn = tableColumns.columns.find((col) => col.filterName === 'destinyWarehouse')
      if (destinyColumn) {
        destinyColumn.filters = apiData.destinyWarehouses.map((warehouse) => ({
          label: warehouse.name,
          value: warehouse.name,
        }))
      }
    }
  }

  useEffect(() => {
    if (users.length && clients.length && loadInitial) {
      fetchTransfers()
    }
  }, [users, clients, loadInitial, fetchTransfers])

  useEffect(() => {
    if (!loadInitial) {
      fetchTransfers()
    }
  }, [filters, columnFilters, currentPage, rowsPerPage, loadInitial, fetchTransfers])

  const handleClearFilters = () => {
    setFilters({
      search: '',
      b2bClientId: [],
      purchaseOrder: '',
      b2bOrderNumber: '',
    })

    setColumnFilters({})
    setCurrentPage(1)
  }

  if (!users.length && !clients.length)
    return (
      <div className='detail-spinner'>
        <Spinner className='m-auto'>Loading...</Spinner>
      </div>
    )

  return (
    <div>
      <Filters filters={filters} setFilters={setFilters} filterOptions={filterOptions} onClear={handleClearFilters} />
      <div style={{ marginTop: '20px' }}>
        <Table
          tableInfo={processTransfersData}
          tableColumns={tableColumns}
          columnFilters={columnFilters}
          setColumnFilters={handleColumnFiltersChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalItems={totalItems}
          loading={loading}
          onRowClick={() => { }}
          dataType={'transfers'}
        />
      </div>
    </div>
  )
}

export default TransfersPage

