import React, { useState, useEffect, useCallback } from 'react'
import BaseButton from '../../../Components/Common/Buttons/BaseButton'
import SelectInput from '../../../Components/Common/Inputs/SelectInput/SelectInput'
import TextInput from '../../../Components/Common/Inputs/TextInput/TextInput'
import debounce from '../../../infra/utils/debounce'
import { useTranslation } from 'react-i18next'

const Filters = ({ filters, setFilters, filterOptions, onClear }) => {
  const { t } = useTranslation()
  const [localFilters, setLocalFilters] = useState({
    search: filters.search || '',
    purchaseOrder: filters.purchaseOrder || '',
    b2bOrderNumber: filters.b2bOrderNumber || '',
  })

  const debouncedSetFilters = useCallback(
    debounce((newFilters) => {
      setFilters((prev) => ({
        ...prev,
        ...newFilters,
      }))
    }, 500),
    [setFilters],
  )

  useEffect(() => {
    debouncedSetFilters({
      search: localFilters.search,
      purchaseOrder: localFilters.purchaseOrder,
      b2bOrderNumber: localFilters.b2bOrderNumber,
    })
  }, [localFilters, debouncedSetFilters])

  const handleTextChange = (e) => {
    const { name, value } = e.target
    setLocalFilters((prev) => ({ ...prev, [name]: value }))
  }

  const clearSelectInput = (field) => {
    setFilters((prev) => ({ ...prev, [field]: [] }))
  }

  const handleClientChange = (value) => {
    setFilters((prev) => {
      const newFilters = { ...prev, b2bClientId: value }
      return newFilters
    })
  }

  return (
    <div className='transfers-filter-wrapper'>
      <div className='filter-container'>
        <div className='filter-item'>
          <TextInput
            label={`${t('SEARCH')}`}
            placeholder={`${t('SEARCH')}...`}
            value={localFilters.search}
            onChange={handleTextChange}
            name='search'
            id='search'
          />
        </div>
        <div className='filter-item'>
          <SelectInput
            label={`${t('CLIENT')}`}
            placeholder={`${t('CHOOSE_CLIENT')}...`}
            value={filters.b2bClientId}
            onChange={handleClientChange}
            options={
              filterOptions.b2bClients?.map((client) => {
                return {
                  label: client.name,
                  value: client.b2bClientId,
                }
              }) || []
            }
            searchable
            clearable
            useSimpleOptions
            clearFunction={() => clearSelectInput('b2bClientId')}
          />
        </div>
        <div className='filter-item'>
          <TextInput
            label={`${t('PURCHASE_ORDER')}`}
            placeholder={`${t('PURCHASE_ORDER')}...`}
            value={localFilters.purchaseOrder}
            onChange={handleTextChange}
            name='purchaseOrder'
            id='purchaseOrder'
          />
        </div>
        <div className='filter-item'>
          <TextInput
            label={`${t('B2B_ORDER_NUMBER')}`}
            placeholder={`${t('B2B_ORDER_NUMBER')}...`}
            value={localFilters.b2bOrderNumber}
            onChange={handleTextChange}
            name='b2bOrderNumber'
            id='b2bOrderNumber'
          />
        </div>
        <BaseButton
          text='Clear'
          onClick={() => {
            setLocalFilters({
              search: '',
              purchaseOrder: '',
              b2bOrderNumber: '',
            })

            onClear()
          }}
          type='secondary'
          width='small'
          icon='columnFilterIcon'
          className='selected'
        />
      </div>
    </div>
  )
}

export default Filters
