export const tableColumns = {
  size: 'normal',
  scroll: {
    scrollable: true,
    scrollHeight: 'calc(100vh - 350px)',
  },
  columns: [
    {
      header: '#',
      field: 'transferId',
      type: 'text',
    },
    {
      header: 'Client',
      field: 'b2bClientName',
      type: 'text',
      columnFilter: 'multiple',
      filterName: 'client',
      filters: [],
    },
    {
      header: 'Status',
      field: 'conclusionLabel',
      type: 'text',
      columnFilter: 'multiple',
      filterName: 'status',
      filters: [],
    },
    {
      header: 'Origin Warehouse',
      field: 'originWarehouseName',
      type: 'text',
      columnFilter: 'multiple',
      filterName: 'originWarehouse',
      filters: [],
    },
    {
      header: 'Destiny Warehouse',
      field: 'destinyWarehouseName',
      type: 'text',
      columnFilter: 'multiple',
      filterName: 'destinyWarehouse',
      filters: [],
    },
    {
      header: 'Total units',
      field: 'totalUnits',
      type: 'number',
      align: 'right',
    },
    {
      header: 'Date',
      field: 'dateCreated',
      type: 'date-time',
      columnFilter: 'date',
      filterName: 'date',
    },
    {
      header: 'Created by',
      field: 'createdByUser',
      type: 'text',
      columnFilter: 'multiple',
      filterName: 'createdBy',
      filters: [],
    },
  ],
  editrow: {
    hasEdit: false,
  },
}

export const updateColumnFilters = (apiData) => {
  if (!apiData) return

  if (apiData.b2bClients) {
    tableColumns.columns.find((col) => col.filterName === 'client').filters = apiData.b2bClients.map((client) => ({
      label: client.name,
      value: client.name,
    }))
  }

  if (apiData.conclusionStatuses) {
    tableColumns.columns.find((col) => col.filterName === 'status').filters = apiData.conclusionStatuses.map(
      (status) => ({
        label: status.conclusionLabel,
        value: status.conclusionLabel,
      }),
    )
  }

  if (apiData.originWarehouses) {
    tableColumns.columns.find((col) => col.filterName === 'originWarehouse').filters = apiData.originWarehouses.map(
      (warehouse) => ({
        label: warehouse.name,
        value: warehouse.name,
      }),
    )
  }

  if (apiData.destinyWarehouses) {
    tableColumns.columns.find((col) => col.filterName === 'destinyWarehouse').filters = apiData.destinyWarehouses.map(
      (warehouse) => ({
        label: warehouse.name,
        value: warehouse.name,
      }),
    )
  }


  if (apiData.users) {
    tableColumns.columns.find((col) => col.filterName === 'createdBy').filters = apiData.users.map((user) => ({
      label: user.fullName,
      value: user.fullName,
    }))
  }
}

