import client from '../config/AxiosConfig'

export const GetTransfers = (
  search = '',
  orderPoSearch = '',
  b2bOrderNumberSearch = '',
  page = 1,
  itemsPerPage = 10,
  languageID = 2,
  status = '',
  originWarehouseId = '',
  destinyWarehouseId = '',
  createdByUserId = '',
  dateFrom = '',
  dateTo = '',
  b2bClientId = '',
) => {
  return client.get('/WarehouseTransfers/ListTransfers', {
    params: {
      freeSearch: search,
      b2bClientSearch: b2bClientId,
      orderPoSearch: orderPoSearch,
      b2bOrderNumberSearch: b2bOrderNumberSearch,
      page: page,
      itemsPerPage: itemsPerPage,
      languageID: languageID,
      conclusionStatuses: status,
      originWarehouseId: originWarehouseId,
      destinyWarehouseId: destinyWarehouseId,
      createdBy: createdByUserId,
      from: dateFrom,
      to: dateTo,
    },
  })
}

